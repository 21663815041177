import * as React from "react"
import styled from "styled-components"
import Title from "../shared/title"
import Bkg from "./background"
import HowTo from "./how-to"

const Box = styled.section`
  position: relative;
`

const Ele = () => {
  return (
    <Box>
      <Title>
        <h2>Join over 28,000 people investing for tomorrow</h2>
        <p>
          At XENO we help you plan, save and invest so that you can achieve
          whatever you desire.
        </p>
      </Title>
      <HowTo />
      <Bkg />
    </Box>
  )
}
export default Ele
