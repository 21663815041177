import * as React from "react"
import styled from "styled-components"
import Ring from "./ring"
import Swirl from "./swirl"
import Square from "./square"
import Goals from "./goals"

const Box = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
`

const Ele = () => {
  return (
    <Box>
      <Square>
        <Ring />
        <Ring inner />
        <Swirl />
        <Goals />
      </Square>
    </Box>
  )
}
export default Ele
