import * as React from "react"
import styled from "styled-components"
import Aurora from "../../images/aurora-h-web.jpg"

const Box = styled.div`
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(10, 14, 21, 61%) 20%, #0a0e15);
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
  }
`

const Ele = () => {
  return (
    <Box>
      <img src={Aurora} alt="" />
    </Box>
  )
}
export default Ele
