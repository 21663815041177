import * as React from "react"
import styled, { css } from "styled-components"
import { Spin, SpinOpposite } from "./ring"

const Rotator = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${props =>
    props.orbit &&
    props.rotation &&
    css`
      transform: scale(${props.orbit}) rotate(${props.rotation}deg);
    `}
`

const Box = styled.div`
  width: 100%;
  height: 100%;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-name: ${Spin};

  ${props =>
    props.duration &&
    css`
      animation-duration: ${props.duration}s;
    `}

  ${props =>
    props.reverse &&
    css`
      animation-direction: reverse;
    `}
`

const Photo = styled.div`
  width: 200px;
  height: 200px;

  ${props =>
    props.scale &&
    css`
      transform: scale(${props.scale});
    `}

  ${props =>
    props.duration &&
    css`
      div {
        animation-duration: ${props.duration}s;
      }
    `}

  ${props =>
    props.reverse &&
    css`
      div {
        animation-direction: reverse;
      }
    `}

  div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-name: ${SpinOpposite};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Ele = ({ src, orbit, duration, reverse, rotation, scale }) => {
  return (
    <Rotator orbit={orbit} rotation={rotation}>
      <Box duration={duration} reverse={reverse}>
        <Photo scale={scale} duration={duration} reverse={reverse}>
          <div>
            <img
              style={{ transform: `rotate(${-1 * rotation}deg)` }}
              rotation={rotation}
              src={src}
              alt=""
            />
          </div>
        </Photo>
      </Box>
    </Rotator>
  )
}
export default Ele
