import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Ele = ({ children }) => {
  return (
    <Box>
      <Content>{children}</Content>
    </Box>
  )
}
export default Ele
