import * as React from "react"
import styled, { css } from "styled-components"

const Box = styled.div`
  width: 100%;
  position: absolute;
  z-index: 2;
  left: -100%;
  right: auto;

  ${props =>
    props.index === 1 &&
    css`
      top: 5%;
    `}
  ${props =>
    props.index === 2 &&
    css`
      top: 15%;
    `}
  ${props =>
    props.index === 3 &&
    css`
      top: 50%;
    `}
  ${props =>
    props.index === 4 &&
    css`
      top: 75%;
    `}


    ${props =>
    props.right &&
    css`
      right: -100%;
      left: auto;
    `}
`

const Circle = styled.div`
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    font-weight: bold;
    position: relative;
    z-index: 1;
  }
`

const Line = styled.div`
  height: 4px;
  background-color: var(--c-mint-100);
  position: relative;
  top: 1.5rem;
  z-index: -1;
`

const Copy = styled.div`
  padding-top: 1rem;
  width: 100%;
  display: flex;
  p {
    width: 75%;
  }

  ${props =>
    props.right &&
    css`
      justify-content: flex-end;

      p {
        text-align: right;
      }
    `}
`

const Wrap = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  ${props =>
    props.right &&
    css`
      align-items: flex-end;
    `}
`

const Ele = ({ index, caption }) => {
  return (
    <Box index={index} right={index == 2 || index == 4}>
      <Line />
      <Wrap right={index == 2 || index == 4}>
        <Circle>
          <span>{index}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="47"
            height="47"
            fill="#0a0e15"
            viewBox="0 0 47 47"
          >
            <circle
              cx="23.5"
              cy="23.5"
              r="22.5"
              stroke="url(#paint0_linear_54_436)"
              strokeWidth="2"
            ></circle>
            <defs>
              <linearGradient
                id="paint0_linear_54_436"
                x1="24.206"
                x2="55.833"
                y1="9.219"
                y2="28.982"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.039" stopColor="#00E2C1"></stop>
                <stop offset="0.95" stopColor="#FFBE00"></stop>
              </linearGradient>
            </defs>
          </svg>
        </Circle>
        <Copy right={index == 2 || index == 4}>
          <p>{caption}</p>
        </Copy>
      </Wrap>
    </Box>
  )
}
export default Ele
