import * as React from "react"
import styled from "styled-components"
import Bkg from "./background"
import Spiral from "./spiral"
import Hero from "./hero-image"
import Copy from "./copy"

const Box = styled.section`
  position: relative;
`

const Ele = () => {
  return (
    <Box>
      <Copy />
      <Hero />
      <Spiral />
      <Bkg />
    </Box>
  )
}
export default Ele
