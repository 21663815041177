import * as React from "react"
import styled from "styled-components"
import Bullet from "./bullet"

const Box = styled.div`
  position: relative;
  z-index: 10;
  padding-top: 8.375rem;
  padding-bottom: 10rem;
`

const Phone = styled.div`
  width: 22.9375rem;
  height: 43.628125rem;
  background-color: red;
  margin: 0 auto;
  position: relative;
`

const Ele = () => {
  return (
    <Box>
      <Phone>
        <Bullet index={1} caption="On your MTN phone, dial *165*5*7#" />
        <Bullet index={2} caption="On your MTN phone, dial *165*5*7#" />
        <Bullet index={3} caption="On your MTN phone, dial *165*5*7#" />
        <Bullet index={4} caption="On your MTN phone, dial *165*5*7#" />
      </Phone>
    </Box>
  )
}
export default Ele
