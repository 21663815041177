import * as React from "react"
import styled from "styled-components"
import Swirl from "../../images/swirl.png"

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), var(--c-blue-201) 80%);
  }

  img {
    transform: rotate(90deg);
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    transform: scale(1.25) rotate(10deg);
    top: 40%;
    left: 0;
    opacity: 65%;
  }
`

const Ele = () => {
  return (
    <Box>
      <img src={Swirl} alt="" />
    </Box>
  )
}
export default Ele
