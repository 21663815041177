import * as React from "react"
import styled from "styled-components"
import Cover from "../../images/cover.jpg"

const Box = styled.div`
  min-height: 100vh;
  position: relative;
  background-color: #0a0e15;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 766px) {
    overflow: hidden;

    a {
      position: absolute;

      img {
        object-fit: contain;
        transform: scale(2.25);
      }
    }
  }
`

const Ele = () => {
  return (
    <Box>
      <a href="https://invest.myxeno.com" rel="noreferrer" target="_blank">
        <img src={Cover} alt="XENO on MTN Mobile Money poster" />
      </a>
    </Box>
  )
}
export default Ele
