import * as React from "react"
import styled, { css, keyframes } from "styled-components"

export const Spin = keyframes`
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
`

export const SpinOpposite = keyframes`
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-360deg);
    }
`

const SpinReverse = keyframes`
    0%{
        transform: rotate(0deg) scale(0.5);
    }
    100%{
        transform: rotate(-360deg) scale(0.5);
    }
`

const Box = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-duration: 10s;
  animation-name: ${Spin};
  opacity: 25%;

  svg {
    width: 100%;
    height: 100%;
  }

  ${props =>
    props.inner &&
    css`
      animation-name: ${SpinReverse};
    `}
`

const Ele = ({ inner }) => {
  return (
    <Box inner={inner}>
      <svg
        viewBox="0 0 1375 1337"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.1882 687.188C2.18819 1065.5 308.873 1372.19 687.188 1372.19C1065.5 1372.19 1372.19 1065.5 1372.19 687.188C1372.19 308.873 1065.5 2.18847 687.188 2.18845C308.873 2.18844 2.18822 308.873 2.1882 687.188ZM0.188202 687.188C0.188186 1066.61 307.768 1374.19 687.188 1374.19C1066.61 1374.19 1374.19 1066.61 1374.19 687.188C1374.19 307.769 1066.61 0.18847 687.188 0.188453C307.769 0.188437 0.188219 307.769 0.188202 687.188Z"
          fill="url(#paint0_linear_64_737)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_64_737"
            x1="1246.45"
            y1="126.196"
            x2="237.531"
            y2="1401.81"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00E2C1" />
            <stop offset="1" stopColor="#00E2C1" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}
export default Ele
