import * as React from "react"
import styled from "styled-components"
import Image from "../../images/hero-image.png"

const Box = styled.div`
  position: absolute;
  width: 100%;
  height: 70vh;
  z-index: 3;
  left: 0;
  top: 0;
`

const Figure = styled.figure`
  width: 21.0625rem;
  height: 33.625rem;
  margin: 0 auto;
  margin-top: 3.9375rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Ele = () => {
  return (
    <Box>
      <Figure>
        <img src={Image} alt="" />
      </Figure>
    </Box>
  )
}
export default Ele
