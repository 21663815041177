import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"

const Box = styled.div`
  transition: opacity 1s, transform 1s;
  opacity: 0;
  transform: translate3d(0, 1rem, 0);

  &.in-view {
    opacity: 1;
    transform: none;

    &:after {
      transform: scale3d(0, 1, 1);
    }
  }
`
const Ele = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  return (
    <Box ref={ref} className={inView ? "in-view" : ""}>
      {children}
    </Box>
  )
}
export default Ele
