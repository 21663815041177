import * as React from "react"
import styled, { css } from "styled-components"
import FadeUp from "../../../../components/shared/fade-up-inview"

const Box = styled.div`
  text-align: center;
  width: 40rem;
  margin: 0 auto;

  h1,
  h2 {
    font-family: var(--xn-font-title);
    font-style: normal;
    font-weight: 700;
    font-size: 4.20875rem;
    line-height: 4.375rem;
    /* background: linear-gradient(122deg, #00e2c1 41.46%, #ffbe00 93.96%); */
    background: linear-gradient(122deg, #02ffda 41.46%, #fcff00 93.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -1.5px;
    background-clip: text;
    text-fill-color: transparent;
    padding-bottom: 3.625rem;
  }

  p {
    width: 70%;
    margin: 0 auto;
  }

  ${props =>
    props.left &&
    css`
      text-align: left;
    `}
`

const BylineText = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  background: linear-gradient(122deg, #00e2c1 41.46%, #ffbe00 93.96%);

  span {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.2px;
    position: relative;
    z-index: 1;
    line-height: 1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: inherit;
    background-color: var(--c-blue-200);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
  }
`

const BylineBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 3rem;

  ${props =>
    props.left &&
    css`
      justify-content: flex-start;
    `}
`

const Ele = ({ children, byline, alignLeft }) => {
  return (
    <Box className="o-title-box" left={alignLeft}>
      <BylineBox left={alignLeft}>
        <BylineText>
          <span>{byline}</span>
        </BylineText>
      </BylineBox>
      <FadeUp>{children}</FadeUp>
    </Box>
  )
}
export default Ele
