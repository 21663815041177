import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Xeno from "../../../../images/xeno-logo-square_fill-mint.svg"
import Momo from "../../../../images/mtn-momo-logo.png"

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 5;
  align-items: center;
  padding: 2.25rem 1.875rem;
  width: 100%;
`
const Logos = styled.div`
  display: flex;

  a {
    display: block;
    margin-right: 1rem;
    height: 3.75rem;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`

const Nav = styled.nav`
  ul {
    display: flex;
    align-items: center;
  }

  a {
    text-transform: uppercase;
    font-size: 0.875rem;
    display: block;
    padding: 1.5rem 1.5rem;
    color: #fff;
    font-weight: bold;
    letter-spacing: -0.2px;
  }
`

const Ele = () => {
  return (
    <Box>
      <Logos>
        <Link to="/">
          <img src={Xeno} alt="" />
        </Link>
        <a href="">
          <img src={Momo} alt="" />
        </a>
      </Logos>
      <Nav>
        <ul>
          <li>
            <Link to="/">Company</Link>
          </li>
          <li>
            <Link to="/">Learn</Link>
          </li>
          <li>
            <Link to="/">Login</Link>
          </li>
          <li>
            <Link to="/">Signup</Link>
          </li>
        </ul>
      </Nav>
    </Box>
  )
}
export default Ele
