import * as React from "react"
import styled from "styled-components"
import Title from "../shared/title"
import Button from "../shared/button"

const Box = styled.div`
  position: absolute;
  z-index: 4;
  width: 100%;
  height: calc(100vh - 5.6875rem);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  p {
    padding-bottom: 4rem;
  }
`

const Wrap = styled.div``

const Ele = () => {
  return (
    <Box>
      <Title>
        <h1>Build a wealth of opportunities!</h1>
        <p>
          XENO makes it possible with goal-based investment, now on MTN MoMo.{" "}
        </p>
        <Button size="large" label="Dial *165*5*7#" />
      </Title>
    </Box>
  )
}
export default Ele
