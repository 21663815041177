import * as React from "react"
import styled from "styled-components"
import GoalPhoto from "./goal-photo"
import EducationGoal from "../../images/goals/goal-education.jpg"
import RetireGoal from "../../images/goals/goal-retire.jpg"
import GroupGoal from "../../images/goals/happy-female.jpg"
import HomeGoal from "../../images/goals/goal-home.jpg"

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 75%;
  filter: grayscale(100%);
`

const goals = [
  {
    src: GroupGoal,
    scale: 0.65,
    rotation: 170,
    orbit: 0.525,
    duration: 400,
    reverse: true,
  },
  {
    src: RetireGoal,
    scale: 0.65,
    rotation: 90,
    orbit: 0.625,
    duration: 70,
    reverse: false,
  },
  {
    src: HomeGoal,
    scale: 0.85,
    rotation: 10,
    orbit: 0.425,
    duration: 200,
    reverse: true,
  },
  {
    src: EducationGoal,
    scale: 1,
    rotation: -45,
    orbit: 0.5,
    duration: 300,
    reverse: false,
  },
]

const Ele = () => {
  return (
    <Box>
      {goals &&
        goals.map((goal, index) => {
          return (
            <GoalPhoto
              key={index}
              src={goal.src}
              scale={goal.scale}
              rotation={goal.rotation}
              orbit={goal.orbit}
              duration={goal.duration}
              reverse={goal.reverse}
            />
          )
        })}
    </Box>
  )
}
export default Ele
