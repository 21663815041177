import * as React from "react"
import styled, { css } from "styled-components"

const Box = styled.div`
  background: linear-gradient(122deg, #00e2c1 41.46%, #ffbe00 93.96%);
  border-radius: 4.2049625rem;
  display: inline-block;
  padding: 1.5rem;
  line-height: 1;

  span {
    font-weight: bold;
    font-size: 1.326875rem;
    color: #000;
    line-height: 1;
  }

  ${props =>
    props.size === "large" &&
    css`
      font-size: 1.89125rem;
      padding: 0.5rem 1rem;
      padding-bottom: 0.75rem;
      letter-spacing: -0.7px;
    `}
`

const Ele = ({ label, size }) => {
  return (
    <Box size={size}>
      <span>{label}</span>
    </Box>
  )
}
export default Ele
