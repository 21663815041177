import * as React from "react"
import styled from "styled-components"
import Swirl from "../../images/swirl.png"
import { Spin } from "./ring"

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 90%;
    height: 90%;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-duration: 60s;
    animation-name: ${Spin};
    opacity: 20%;
  }
`

const Ele = () => {
  return (
    <Box>
      <img src={Swirl} alt="" />
    </Box>
  )
}
export default Ele
