import * as React from "react"
import styled from "styled-components"
import Meta from "../utilities/seo"
import Splash from "../microsites/mtn-momo/components/splash/splash"
import Placeholder from "../microsites/mtn-momo/components/splash/placeholder"
import Header from "../microsites/mtn-momo/components/shared/header"
import GetStarted from "../microsites/mtn-momo/components/get-started/get-started"
import Logo from "../images/xeno-logo-square_fill-mint.svg"

const Main = styled.main`
  height: 100vh;
  position: relative;
  background-color: #0a0e15;
  color: #fff;
  font-size: 1.333125rem;
  font-family: var(--xn-font-body);
  font-style: normal;
  font-weight: 400;
  line-height: 2.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const Box = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
  }

  span {
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: -0.6px;
  }
`

const Ele = () => {
  return (
    <>
      <Meta title="XENO Payments" />
      <Main>
        <Box>
          <img src={Logo} alt="XENO Payments Logo" />
          <span>Payments</span>
        </Box>
      </Main>
    </>
  )
}
export default Ele
